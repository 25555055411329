import React from 'react';
import Chip from '@mui/material/Chip';
// import { makeStyles } from '@mui/core/styles';
import Avatar from '@mui/material/Avatar';
import ClearIcon from '@mui/icons-material/Clear';
import { lightBlue } from '@mui/material/colors';

// const useStyles = makeStyles((theme) => ({
//   avatar: {
//     backgroundColor: theme.palette.grey[50],
//     border: `1px solid ${theme.palette.info.main}`,
//     color: theme.palette.info.main,
//   },
// }));

export default function ClearButton({ onClick }) {
  // const classes = useStyles();
  return (
    // <Avatar sx={{ bgcolor: lightBlue[500], cursor: `pointer` }} onClick={onClick}>
    <Chip icon={<ClearIcon />} onClick={onClick} />
    // </Avatar>
  );
}
