
  import React, { useState, useEffect } from 'react';
  import { useLazyQuery, gql } from '@apollo/client';
  import './ProductSearch.css';
  import { OutlinedInputField } from './pages/components/OutlinedInputField';

  const ProductSearch = ({ onSelect, onRemoveFromSelectedProducts, selectedProducts }) => {
    const [pagination, setPagination] = useState({
      page: 1,
      totalPages: 1,
      totalCount: 0
    });

    const [searchTerm, setSearchTerm] = useState('');
    // const [selectedProducts, setSelectedProducts] = useState([]);
    const [results, setResults] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 5;


    const SEARCH_PRODUCTS_QUERY = gql`
      query SearchProducts($keyword: String!, $page: Int!, $pageSize: Int!) {
        searchProducts(keyword: $keyword, page: $page, pageSize: $pageSize) {
          totalCount
          products {
            product
            thumbnailUrl
            newAveragePrice
            numberOfTransactions
          }
        }
      }
    `;

    const [searchProducts, { loading, data }] = useLazyQuery(SEARCH_PRODUCTS_QUERY);
      useEffect(() => {
        // Execute code only once when the component mounts
        // You can add any initialization logic here
        searchProducts({ variables: { keyword: '', page: 1, pageSize: pageSize }})
      }, []); // Empty dependency array


    const loadPage = (newPage) => {
      if (newPage <= pagination.totalPages) {
        setPagination((prevPagination) => ({
          ...prevPagination,
          page: newPage,
        }));

        searchProducts({
          variables: { keyword: searchTerm, page: newPage, pageSize },
        });
      }
    };

    useEffect(() => {
      if (data && data.searchProducts.products.length > 0) {
        if (data.searchProducts.products.length < pageSize) {
          setHasMore(false);
        }
        setResults(data.searchProducts.products);
        setLoadingMore(false);

        // Update pagination state after receiving data
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalPages: Math.ceil(data.searchProducts.totalCount / pageSize),
          totalCount: data.searchProducts.totalCount
        }));
      }
    }, [data]);

    const handleSearch = (event) => {
      setSearchTerm(event.target.value);
      setHasMore(true);
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: 1,
      }));
      setResults([]);
      searchProducts({ variables: { keyword: event.target.value, page: 1, pageSize } });
    };

    const addToSelectedProducts = (product) => {
      onSelect(product);
    };

    const removeFromSelectedProducts = (product) => {
      onRemoveFromSelectedProducts(product);
    }

    const renderPageButtons = () => {
      const buttons = [];
      const maxButtonsToShow = 5;

      for (let i = pagination.page - Math.floor(maxButtonsToShow / 2); i <= pagination.page + Math.floor(maxButtonsToShow / 2); i++) {
        if (i >= 1 && i <= pagination.totalPages) {
          buttons.push(
            <button
              key={i}
              onClick={() => loadPage(i)}
              disabled={i === pagination.page}
              className={i === pagination.page ? 'active' : ''}
            >
              {i}
            </button>
          );
        }
      }

      return buttons;
    };

    const renderSearchLabel = () => {
      if (pagination.totalCount == 0) return undefined;
      return (
        <div className="search-label">
          {pagination.totalPages && (
            <p>
              Showing {pagination.totalCount.toLocaleString()} Results{" "}
              {searchTerm !== "" && (
                <span>
                  for <b>&ldquo;{searchTerm}&rdquo;</b>
                </span>
              )}
            </p>
          )}
        </div>
      );
    };

    const handleItemClick = (product) => {
      onSelect(product);
      setSearchTerm('')
    }

    const renderSearchResults = () => {
      return results.map(p => <div className="product-search-result-item" onClick={() => handleItemClick(p)}>
      <img src={p.thumbnailUrl} style={{ width: 40, height: 40 }} />
      <span>{p.product} </span>
      </div>);
    }

    // <input
    //   type="text"
    //   className="search-bar"
    //   value={searchTerm}
    //   onChange={handleSearch}
    //   placeholder="Search for Product / Brand"
    // />

    return (
      <div className="product-search-container">

          <OutlinedInputField label="Search for Product / Brand" variant="outlined" value={searchTerm} onChange={handleSearch} fullWidth autoComplete='off' />
          {searchTerm != undefined && searchTerm != '' ?
          <div className="product-search-results">
            {renderSearchResults()}
          </div> : null}
        </div>
    );
  };

  export default ProductSearch;
