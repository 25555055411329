import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import NextButton from './components/NextButton';
import PaymentMethodPage from './PaymentMethodPage';


const ContactInformationPage = ({ onSelect, onSubmit }) => {
 const [firstName, setFirstName] = useState("")
 const [lastName, setLastName] = useState("")
 const [phoneNumber, setPhoneNumber] = useState("")
 const [email, setEmail] = useState("")
 const [shippingAddress, setShippingAddress] = useState("");
 const [city, setCity] = useState("")
 const [state, setState] = useState("")
 const [zipCode, setZipCode] = useState("");
 const [paypalEmail, setPaypalEmail] = useState("")
 const [venmoUsername, setVenmoUsername] = useState("")

 const [firstNameError, setFirstNameError] = useState(false)
 const [lastNameError, setLastNameError] = useState(false)
 const [phoneNumberError, setPhoneNumberError] = useState(false)
 const [emailError, setEmailError] = useState(false)
 const [shippingAddressError, setShippingAddressError] = useState(false);
 const [cityError, setCityError] = useState(false)
 const [stateError, setStateError] = useState(false)
 const [zipCodeError, setZipCodeError] = useState(false);
 const [paypalEmailError, setPaypalEmailError] = useState(false)
 const [venmoUsernameError, setVenmoUsernameError] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState({});
  const handleSelect = (method) => {
    setPaymentMethod(method);
    setPaypalEmailError(false);
    setVenmoUsernameError(false);
  }

  function isValidEmail(email) {
  // Regular expression for validating email addresses
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleSubmit = (event, contactInformation, paymentMethod) => {
    event.preventDefault();
    var formIncomplete = false;
    if (firstName == '') {
        setFirstNameError(true);
        formIncomplete = true;
    }
    if (lastName == '') {
        setLastNameError(true);
        formIncomplete = true;
    }
    if (phoneNumber == '') {
        setPhoneNumberError(true);
        formIncomplete = true;
    }
    if (email == '') {
        setEmailError(true);
        formIncomplete = true;
    }
    if (shippingAddress == '') {
        setShippingAddressError(true);
        formIncomplete = true;
    }
    if (city == '') {
        setCityError(true);
        formIncomplete = true;
    }
    if (state == '') {
        setStateError(true);
        formIncomplete = true;
    }
    if (zipCode == '') {
        setZipCodeError(true);
        formIncomplete = true;
    }
    if (paymentMethod == 'Venmo' && venmoUsername == '') {
      setVenmoUsernameError(true);
      formIncomplete = true;
    }

    if (paymentMethod == 'PayPal' && paypalEmail == '' || !isValidEmail(paypalEmail)) {
      setPaypalEmailError(true);
      formIncomplete = true;
    } else {
      setPaypalEmailError(false);
    }

    if (formIncomplete == false) {
      onSubmit(contactInformation, Object.assign({}, {
        paymentMethod: paymentMethod,
        username: paymentMethod == 'Venmo' ? venmoUsername : paymentMethod == 'PayPal' ? paypalEmail : undefined
      }));
    }
  }

  const handlePaypalEmailChange = event => {
    setPaypalEmail(event.target.value);
    setPaypalEmailError(false)
  }

  const handleVenmoUsernameChange = event => {
    setVenmoUsername(event.target.value);
    setVenmoUsernameError(false)
  }

  return (
    <div>
      <div className="checkout-page">
        <Grid>
              <Typography gutterBottom variant="h5">
                Contact Information
            </Typography>
              <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                Fill out the form for us to create your shipping label.
            </Typography>
              <form>
                <Grid container spacing={1}>
                  <Grid xs={12} sm={6} item>
                    <TextField placeholder="Enter first name" label="First Name" variant="outlined" value={firstName} error={firstNameError} onChange={e => setFirstName(e.target.value)} onFilled={() => setFirstNameError(false)} fullWidth required />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField placeholder="Enter last name" label="Last Name" variant="outlined" value={lastName} error={lastNameError} onChange={e => setLastName(e.target.value)} onFilled={() => setLastNameError(false)} fullWidth required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField type="number" placeholder="Enter phone number" label="Phone" variant="outlined" value={phoneNumber} error={phoneNumberError} onChange={e => setPhoneNumber(e.target.value)} onFilled={() => setPhoneNumberError(false)} fullWidth required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField type="email" placeholder="Enter email" label="Email" variant="outlined" value={email} error={emailError} onChange={e => setEmail(e.target.value)} onFilled={() => setEmailError(false)} fullWidth required />
                  </Grid>
                  <Grid xs={12} sm={12} item>
                    <TextField label="Shipping Address" placeholder="Enter shipping address" variant="outlined" value={shippingAddress} error={shippingAddressError} onChange={e => setShippingAddress(e.target.value)} onFilled={() => setShippingAddressError(false)} fullWidth required />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField label="City" placeholder="Enter City" variant="outlined" value={city} error={cityError} onChange={e => setCity(e.target.value)} onFilled={() => setCityError(false)} fullWidth required />
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <TextField label="State" placeholder="Enter state" variant="outlined" value={state} error={stateError} onChange={e => setState(e.target.value)} onFilled={() => setStateError(false)} fullWidth required />
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <TextField label="Zip Code" placeholder="Enter zip code" variant="outlined" value={zipCode} error={zipCodeError} onChange={e => setZipCode(e.target.value)} onFilled={() => setZipCodeError(false)} fullWidth required />
                  </Grid>
                </Grid>
              </form>
        </Grid>
      </div>
      <PaymentMethodPage onSelect={handleSelect} onSubmit={(event) => handleSubmit(event, {
        firstName,
        lastName,
        phoneNumber,
        email,
        shippingAddress,
        city,
        state,
        zipCode
      }, paymentMethod)}
      selectedPaymentMethod={paymentMethod}
      paypalEmail={paypalEmail}
      paypalEmailError={paypalEmailError}
      venmoUsername={venmoUsername}
      venmoUsernameError={venmoUsernameError}
      onPaypalEmailChange={handlePaypalEmailChange}
      onVenmoUsernameChange={handleVenmoUsernameChange}
      />
    </div>
  );
}

export default ContactInformationPage;
