import React, { useState, useEffect } from 'react';
import CartItemCSS from './CartItem.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ProductSearch from '../../ProductSearch';
import ClearButton from './ClearButton';

// const ComboBox = ({ results = [] })  => {
//   return (
//     <Autocomplete
//       disablePortal
//       id="combo-box-demo"
//       options={results}
//       sx={{ width: 300 }}
//       renderInput={(params) => <TextField {...params} label="Product" />}
//     />
//   );
// }

const CartItem = ({ product, quantity, onAddSelectedProduct, onRemoveFromSelectedProducts, onPaymentMethodChange, onQuantityChange }) => {
  const [paymentMethod, setPaymentMethod] = useState("cash");

  // const [quantity, setQuantity] = useState(1);
  //
  const handleQuantityChange = (event) => {
    const newQuantity = Math.max(parseInt(event.target.value, 10) || 1, 1);
    onQuantityChange(product, newQuantity)
    // setQuantity(newQuantity);
    //
    // // Save quantity to local storage with a unique key for each product
    // localStorage.setItem(`quantity-${product.thumbnailUrl}`, newQuantity.toString());
  };

  const handlePaymentMethodChange = (event) => {
    const newPaymentMethod = event.target.value;
    setPaymentMethod(newPaymentMethod);

    localStorage.setItem(`payment-method-${product.thumbnailUrl}`, newPaymentMethod)
    onPaymentMethodChange(product, newPaymentMethod);
  }

  const removeFromSelectedProducts = (product) => {
    onRemoveFromSelectedProducts(product);
  };

  const handleSelect = (product) => {
    onAddSelectedProduct(product)
  }

  const calculateTotalPrice = (product, paymentMethod) => {
    var price = 0;
    if (paymentMethod === 'store-credit') {
      price = product.newAveragePrice * (104 / 91) * quantity;
    } else if (paymentMethod === 'cash') {
      price = product.newAveragePrice * quantity;
    }
    return Math.round(price * 100) / 100;
  };

  if (product == undefined) {
    return (
      <li key="first-item" className="cart-item">
            <div className="cart-item-info">
              <ProductSearch onSelect={handleSelect} />
            </div>
      </li>);
  }

    // return (
    //
    //     <li key={product.product} className="cart-item">
    //       <div className="cart-item-info">
    //
    //       </div>
    //     </li>
    // )


    // <div className="cart-item-metadata">
    //   <label htmlFor={`store-credit-price-${product.product}`}>Store Credit: ${calculateTotalPrice(product, "store-credit")}</label>
    //   <label htmlFor={`cash-price-${product.product}`}>Store Credit: ${calculateTotalPrice(product, "cash")}</label>
    // </div>


  const handleDeleteButtonClick = () => {
    onRemoveFromSelectedProducts(product);
  }

  return (
    <li key={product.product} className="cart-item">
      <div className="cart-item-info">
        <div className="cart-item-image-wrapper">
          <img src={product.thumbnailUrl} alt={product.product} />
        </div>
        <div className="cart-item-metadata">
          <p>{product.product}</p>
          <div className="price-quantity">
            <div className="quantity-container">
                <label htmlFor={`quantity-${product.product}`}>Quantity:</label>
                <input
                  type="number"
                  id={`quantity-${product.thumbnailUrl}`}
                  name={`quantity-${product.thumbnailUrl}`}
                  value={quantity}
                  min={1}
                  onChange={handleQuantityChange}
                />
            </div>
            <div className="payment-method-container">
              <FormControl>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                >
                  <FormControlLabel value="store-credit" control={<Radio />} label={<div>Store Credit <b style={{ paddingLeft: 8 }}>${calculateTotalPrice(product, "store-credit")}</b> </div>} />
                  <FormControlLabel value="cash" control={<Radio />} label={<div>Cash <b style={{ paddingLeft: 16 }}>${calculateTotalPrice(product, "cash")}</b> </div>} />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="cart-item-image-wrapper">
          <ClearButton onClick={handleDeleteButtonClick}/>
        </div>
      </div>
    </li>
  );
};



export default CartItem;
