import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import HomePage from './pages/HomePage'; // Create this component
import CartPage from './pages/CartPage'; // Create this component
import ContactInformationPage from './pages/ContactInformationPage';
import PaymentMethodPage from './pages/PaymentMethodPage';
import ConfirmationPage from './pages/ConfirmationPage';
import { useMutation } from '@apollo/client';
import { GENERATE_AND_SEND_QUOTE } from './graphql/queries';

import NavigationBar from './NavigationBar';
import AppCSS from './App.css';
import Index from './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const App = () => {
  const navigate = useNavigate();
  const [generateAndSendQuote] = useMutation(GENERATE_AND_SEND_QUOTE);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [quantity, setQuantity] = useState({});

  const handleQuantityChange = (product, quantity) => {
    const newQuantity = Math.max(parseInt(quantity, 10) || 1, 1);
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [product.thumbnailUrl]: newQuantity,
    }));

    // Save quantity to local storage with a unique key for each product
    localStorage.setItem(`quantity-${product.thumbnailUrl}`, newQuantity.toString());
  };

  const handlePaymentMethodChange = (product, paymentMethod) => {
    setPaymentMethod((prevMethods) => ({
      ...prevMethods,
      [product.thumbnailUrl]: paymentMethod,
    }));
  };

  // Load selected products from local storage on component mount
  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];
    setSelectedProducts(storedProducts);

    // TODO: Store quantities from local storage in state


  }, []); // Empty dependency array ensures it runs only on mount


  useEffect(() => {
    for (var i = 0; i < selectedProducts.length; i++) {
      if (paymentMethod[selectedProducts[i].thumbnailUrl] == null) {
        handlePaymentMethodChange(selectedProducts[i], "cash")
      }
      if (quantity[selectedProducts[i].thumbnailUrl] == null) {
        handleQuantityChange(selectedProducts[i], 1)
      }
    }
  }, [selectedProducts]); // Depend on the product's unique identifier

  const handleSelect = (product) => {
    // console.log(product);
    // setSelectedProducts((prevSelectedProducts) => [...prevSelectedProducts, product]);
    // Retrieve existing products from localStorage
    const storedProducts = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    // Use a Set for unique products
    const uniqueProducts = new Set([...storedProducts, product]);

    // Convert Set back to an array
    const updatedProducts = [...uniqueProducts];

    // Save the updated products to localStorage
    localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));

    // Update state with the unique products
    setSelectedProducts(updatedProducts);
  }

  const handleRemoveFromSelectedProducts = (product) => {
      console.log("REMOVING PRODUCT: ", product);
      // CHANGE TO ID when added to DB
      const updatedProducts = selectedProducts.filter((p) => p.thumbnailUrl !== product.thumbnailUrl);

      // Save the updated products to localStorage
      localStorage.setItem('selectedProducts', JSON.stringify(updatedProducts));

      localStorage.removeItem(`quantity-${product.thumbnailUrl}`);

      // Update state with the updated products
      setSelectedProducts(updatedProducts);
  };

  const handleGenerateQuote = async (contactInformation, pm) => {
    console.log("CONTACT INFORMATION: ", contactInformation);
    console.log("PM: ", pm);
    try {
      const { data } = await generateAndSendQuote({
        variables: {
          contactInformation: contactInformation,
          selectedItems: selectedProducts.map(p => Object.assign({}, {
            productName: p.product,
            price: p.newAveragePrice,
            quantity: quantity[p.thumbnailUrl],
            redeemAsCash: paymentMethod[p.thumbnailUrl] == "cash",
            redeemAsStoreCredit:paymentMethod[p.thumbnailUrl] == "store-credit"
          })),
          paymentMethod: pm
        },
      });
      console.log('Generated Quote:', data.generateAndSendQuote);
      navigate('/checkout/success'); // Navigate to the '/confirmation' route
    } catch (error) {
      console.error('Error generating and sending quote:', error);
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <div className="app-container">
          <Helmet>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
          </Helmet>
          <NavigationBar selectedProducts={selectedProducts} />
          <div className="main-container">
            <div className="content-container">
              <Routes>
                <Route path="/" element={<CartPage onRemoveFromSelectedProducts={handleRemoveFromSelectedProducts} onAddSelectedProduct={handleSelect} selectedProducts={selectedProducts} paymentMethod={paymentMethod} quantity={quantity} onPaymentMethodChange={handlePaymentMethodChange} onQuantityChange={handleQuantityChange}/>} />
                <Route path="/checkout/1" element={<ContactInformationPage onSubmit={handleGenerateQuote} />} />
                <Route path="/checkout/2" element={<PaymentMethodPage />} />
                <Route path="/checkout/success" element={<ConfirmationPage />} />
              </Routes>
            </div>
          </div>
        </div>
    </ThemeProvider>
  );
};

export default App;
