import React from 'react';
import NavigationBarStyling from './NavigationBar.css';
import { NavLink } from 'react-router-dom';
// import Popover from './Popover';

const NavigationBar = ({ selectedProducts }) => {
  return (
    <div className="navigation-bar">
      <div className="navigation-bar-content">
          <div class="elementor-background-video-container" style={{height: 172.117 }} data-vimeo-initialized="true">
      				<div class="elementor-background-video-embed"></div>
      				<iframe src="https://player.vimeo.com/video/831288026?muted=1&amp;autoplay=1&amp;dnt=1&amp;loop=1&amp;background=1&amp;app_id=122963"
                      width="426"
                      height="240"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="AdobeStock_133519002"
                      data-ready="true"
                      className="elementor-background-video-embed"
                      style={{ width: 1420, height: 800 }}>
              </iframe>
          </div>
          <div className="elementor-widget-container">
              <NavLink to="/">
                <img fetchpriority="high" width="662" height="521" src="https://bucketlistguitars.com/wp-content/uploads/2023/06/Cropped-Logo-PNG.png" class="elementor-animation-shrink attachment-full size-full wp-image-538" alt="" srcset="https://bucketlistguitars.com/wp-content/uploads/2023/06/Cropped-Logo-PNG.png 662w, https://bucketlistguitars.com/wp-content/uploads/2023/06/Cropped-Logo-PNG-416x327.png 416w, https://bucketlistguitars.com/wp-content/uploads/2023/06/Cropped-Logo-PNG-300x236.png 300w" sizes="(max-width: 662px) 100vw, 662px" style={{ width: "120px", height: "94px" }} />
              </NavLink>
          </div>
          <div className="navigation-bar-links" >
            <ul style={{ display: "flex" }}>
              <li>
                <NavLink to="/cart">Cart {selectedProducts.length > 0 ? `(${selectedProducts.length})` : null}</NavLink>
              </li>
            </ul>
          </div>
        </div>
    </div>
  )
};

export default NavigationBar;
