import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import PayPalLogo from '../../assets/PayPal_Logo.png';
import CheckLogo from '../../assets/Check_Logo.png';

export default function PaymentMethodCard({ method, description, imageSrc, onSelect, selected }) {
  let imageUrl = method === "PayPal" ? PayPalLogo
                    : method === "Venmo" ? "https://images.ctfassets.net/gkyt4bl1j2fs/4pp7c6YvscP62B8UGRR2NA/2b69b744d7d81df1f3e22a599fc13857/dark_background.png?w=640&h=288&q=50&fm=webp&bg=transparent"
                    : CheckLogo;
  return (
    <Card sx={{ maxWidth: 345 }} raised={selected} elevation={selected ? 20: 0}>
      <CardActionArea onClick={() => onSelect(method)}>
        <CardMedia
          component="img"
          height="140"
          image={imageUrl}
          style={{objectFit: 'contain', backgroundColor: 'black', padding: '25px', filter: method == "Check" ? 'brightness(2.5)' : undefined, paddingBottom:  method == "Check" ? '45px': '25px' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {method}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
