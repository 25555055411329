import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import NextButton from './components/NextButton';
import PaymentMethodCard from './components/PaymentMethodCard';


const PaymentMethodPage = ({ selectedPaymentMethod, onSelect, paypalEmail, venmoUsername, onPaypalEmailChange, onVenmoUsernameChange, paypalEmailError, venmoUsernameError, onPaypalEmailErrorChange, onVenmoUsernameErrorChange, onSubmit }) => {
  return (
    <div className="checkout-page">
      <Grid>
          <Typography gutterBottom variant="h5">
              Select Payment Method
          </Typography>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Please select how you would like to get paid for your cash items.
          </Typography>
          <Grid container spacing={1}>
            <Grid xs={12} sm={4} item>
              <PaymentMethodCard method="PayPal" description="Receive PayPal payment upon delivery." imageSrc="./assets/PayPal_logo.png" onSelect={onSelect} selected={selectedPaymentMethod == "PayPal"} />
            </Grid>
            <Grid xs={12} sm={4} item>
              <PaymentMethodCard method="Venmo" description="Receive Venmo payment upon delivery." onSelect={onSelect} selected={selectedPaymentMethod == "Venmo"} />
            </Grid>
            <Grid xs={12} sm={4} item>
              <PaymentMethodCard method="Check" description="Check in the mail upon delivery." onSelect={onSelect} selected={selectedPaymentMethod == "Check"} />
            </Grid>
            {
              selectedPaymentMethod == "PayPal" &&
              <Grid xs={12} sm={12} item style={{ marginTop: "24px" }}>
                <TextField label="PayPal Email" placeholder="Enter PayPal email" variant="outlined" fullWidth required value={paypalEmail} onChange={onPaypalEmailChange} onFilled={() => onPaypalEmailErrorChange(false)} error={paypalEmailError} />
              </Grid>
            }
            {
              selectedPaymentMethod == "Venmo" &&
              <Grid xs={12} sm={12} item style={{ marginTop: "24px" }}>
                <TextField label="Venmo Username" placeholder="Enter Venmo Username" variant="outlined" fullWidth required value={venmoUsername} onChange={onVenmoUsernameChange} onFilled={() => onVenmoUsernameErrorChange(false)} error={venmoUsernameError} />
              </Grid>
            }
            <Grid item xs={12} style={{ marginTop: "24px" }}>
                <NextButton fullWidth title="Submit" onClick={onSubmit} />
            </Grid>
          </Grid>
      </Grid>
    </div>
  );
}

export default PaymentMethodPage;
