import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import NextButton from './components/NextButton';
import PaymentMethodCard from './components/PaymentMethodCard';


function ConfirmationPage() {
  return (
    <div className="checkout-page" style={{ textAlign: 'center' }}>
      <Grid>
          <Typography gutterBottom variant="h5">
              Thank You For Your Order!
          </Typography>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              You will receive an email shortly with next steps.
          </Typography>
          <img src="https://static-00.iconduck.com/assets.00/success-icon-512x512-qdg1isa0.png" style={{ margin: 'auto', display: 'block', width: 100, marginTop: 48 }}/>
      </Grid>
    </div>
  );
}

export default ConfirmationPage;
