import React from 'react';
import './HowItWorks.css';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentsIcon from '@mui/icons-material/Payments';
import Button from '@mui/material/Button';

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2 className="title">How It Works</h2>
      <div className="steps-container">
        <div className="step">
        <div className="icon-background">
          <LocalOfferIcon className="icon" />
        </div>
          <h3 className="step-title">Get Your Offer</h3>
          <p className="step-description">Input the items you'd like to sell below to compile your offer.</p>
        </div>
        <div className="step">
          <div className="icon-background">
            <LocalShippingIcon className="icon" />
          </div>
          <h3 className="step-title">Ship</h3>
          <p className="step-description">We'll send you a prepaid shipping label once your order has been received.</p>
        </div>
        <div className="step">
          <div className="icon-background">
            <PaymentsIcon className="icon" />
          </div>
          <h3 className="step-title">Get Paid</h3>
          <p className="step-description">We'll submit payment in the method of your choosing as soon as your items arrive to the store and have been reviewed.</p>
        </div>
      </div>
      <Button variant="outlined" style={{ marginTop: 16, color: '#00c6ff' }}>View All FAQs</Button>
    </div>
  );
};

export default HowItWorks;
