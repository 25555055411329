import React from "react";
import FooterCss from './Footer.css';

const Footer = (props) => (
    <footer className="footer">
        {props.children}
    </footer>
);

export default Footer;
