// CartPage.js

import React, { useState, useEffect } from 'react';
import CartPageCSS from './CartPage.css'
import CartItem from './components/CartItem';
import CartSummaryPanel from './components/CartSummaryPanel';
import AddButton from './components/AddButton';
import NextButton from './components/NextButton';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Footer from './components/Footer';
import { NavLink } from 'react-router-dom';
import HowItWorks from './components/HowItWorks';

const CartPage = ({ selectedProducts, paymentMethod, quantity, onRemoveFromSelectedProducts, onAddSelectedProduct, onPaymentMethodChange, onQuantityChange }) => {

const [emptyRows, setEmptyRows] = useState([]);
// const [paymentMethod, setPaymentMethod] = useState({});
//
// useEffect(() => {
//   for (var i = 0; i < selectedProducts.length; i++) {
//     if (paymentMethod[selectedProducts[i].thumbnailUrl] == null) {
//       handlePaymentMethodChange(selectedProducts[i], "store-credit")
//     }
//
//   }
// }, [selectedProducts]); // Depend on the product's unique identifier
//
// const handlePaymentMethodChange = (product, paymentMethod) => {
//   setPaymentMethod((prevMethods) => ({
//     ...prevMethods,
//     [product.thumbnailUrl]: paymentMethod,
//   }));
// };

const handleRemoveFromSelectedProducts = (product) => {
  onRemoveFromSelectedProducts(product)
}

  // return (
  //   <div className="cart-container">
  //     <h2>Your Cart</h2>
  //     <ul className="selected-products">
  //       {selectedProducts.length === 0 ? (
  //         <p>Your cart is empty.</p>
  //       ) : (
  //         selectedProducts.map((product) => (
  //           <li key={product.thumbnailUrl} className="selected-product">
  //             <span>{product.product}</span>
  //             <button onClick={() => removeFromSelectedProducts(product)}>Remove From Cart</button>
  //           </li>
  //         ))
  //       )}
  //     </ul>
  //   </div>
  // );


    // Function to get the price of a product by ID
 const getProductPrice = (productId) => {
   // Replace this with your logic to fetch the price of the product
   const product = selectedProducts.find((item) => item.thumbnailUrl === productId);
   return product ? product.newAveragePrice : 0;
 };


  // return (
  //   <div className="cart-container">
  //     <h2>Your Cart</h2>
  //     <ul className="cart-list">
  //       {selectedProducts.map((product) => (
  //         <CartItem product={product} onRemoveFromSelectedProducts={onRemoveFromSelectedProducts} />
  //       ))}
  //     </ul>
  //   </div>
  // )

  const handleAddButtonClick = () => {
    // setEmptyRows(previousRows => [...previousRows, ""])
    // redirect("/submit-order/1")
  }

  const handleAddSelectedProduct = (product) => {
    onAddSelectedProduct(product);
  }

  // Function to calculate the total price based on payment method
  const calculateTotalPrice = (product, quantity, paymentMethod) => {
    if (paymentMethod === 'store-credit') {
      return product.newAveragePrice * (104 / 91) * quantity;
    } else if (paymentMethod === 'cash') {
      return product.newAveragePrice * quantity;
    }
    return 0; // Default case
  };

  // Ensure that selectedProducts is defined before using reduce

  var storeCreditProducts = selectedProducts.filter(p => paymentMethod[p.thumbnailUrl] == "store-credit");
  var cashProducts = selectedProducts.filter(p => paymentMethod[p.thumbnailUrl] == "cash");

  var storeCreditTotal = storeCreditProducts
    ? storeCreditProducts.reduce((total, product) => total + calculateTotalPrice(product, quantity[product.thumbnailUrl], "store-credit"), 0)
    : 0;
  var cashTotal = cashProducts
    ? cashProducts.reduce((total, product) => total + calculateTotalPrice(product, quantity[product.thumbnailUrl], "cash"), 0)
    : 0;

  storeCreditTotal = Math.round(storeCreditTotal * 100) / 100
  cashTotal = Math.round(cashTotal * 100) / 100

  return (
    <div className="cart-page">
      <div className="cart-items">
        <ul>
          {selectedProducts.length > 0 && selectedProducts.map((product) => (
            <CartItem
              key={product.thumbnailUrl}
              product={product} // Pass payment method as a prop
              quantity={quantity[product.thumbnailUrl] != undefined ? quantity[product.thumbnailUrl]: 1}
              onQuantityChange={(product, quantity) => onQuantityChange(product, quantity)}
              onPaymentMethodChange={(product, paymentMethod) => onPaymentMethodChange(product, paymentMethod)}
              onRemoveFromSelectedProducts={(product) => handleRemoveFromSelectedProducts(product)}
              onAddSelectedProduct={(product) => onAddSelectedProduct(product)}
            />
          ))}
        </ul>
        <ul>
          <CartItem
              key={"newItem"}
              onAddSelectedProduct={handleAddSelectedProduct}
            />
        </ul>
        {selectedProducts.length > 0 &&
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <NavLink to="/checkout/1">
                <NextButton onClick={handleAddButtonClick}/>
              </NavLink>
            </Grid>
          </Grid>
        }
        <Footer>
          <Grid
            container
            spacing={0}
            direction="horizontally"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            style={{ marginTop: 48 }}
          >
            <Grid item xs={5}>
              <div style={{ marginBottom: 8 }}> <b style={{fontSize: "large "}}>CASH TOTAL</b> </div>
              <span><b>${cashTotal} </b></span>
            </Grid>
            <Divider orientation="vertical" flexItem className="divider" />
            <Grid item xs={5}>
              <div style={{ marginBottom: 8 }}> <b style={{fontSize: "large "}}>STORE CREDIT TOTAL</b> </div>
              <span><b>${storeCreditTotal} </b></span>
            </Grid>
          </Grid>
          <p style={{fontSize: "small", textAlign: "center", marginTop: 32 }}>Can’t find the item you’re looking to sell? Please email us at <a href="mailto:info@bucketlistguitars.com" style={{ color: '#00c6ff' }}>info@bucketlistguitars.com</a>.</p>

          <HowItWorks />
        </Footer>
      </div>

    </div>
  );


};

export default CartPage;
